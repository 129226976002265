module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/app","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-QS307JR375","cookieName":"gdpr-analytics-enabled","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"G-QS307JR375","cookieName":"gdpr-analytics-enabled","dataLayerName":"dataLayer"},"hotjar":{"hjid":"3059057","hjsv":"6","cookieName":"gdpr-analytics-enabled"},"environments":["production","development"]},
    }]
